import React from 'react';
import './style/App.css'
import Sidebar from './components/sidebar/Sidebar';
import AppRoutes from './components/AppRoutes'
import BurgerMenuSmallSc from './components/burgerMenuSmallSc/BurgerMenuSmallSc';
import ScrollToTop from './components/scrollToTopPage/ScrollToTop';
import BtnScrollToUp from './components/ui/scrollToUpBtn/ScrollToUpBtn';
import LoadingTest from './components/blockTest/BlockTest';

function App() {
  return (
    <>
      <ScrollToTop />
      <Sidebar/>
      <BurgerMenuSmallSc/>
      <AppRoutes/>
    </>
    
    
  );
}

export default App;
