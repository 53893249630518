import React from 'react';
import cl from './TitleSection.module.css';

const TitleSection = ({children, ...props}) => {
    return (
        <div {...props} className={cl.title_section_of_page}>
            <div className={cl.title_text}>
                <h1>{children}</h1>
            </div>
        </div>
    );
};

export default TitleSection;