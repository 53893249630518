import { FaInstagram, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { FBIcon, InstagramIcon, LinkedinIcon, YouTubeIcon } from "../svgIcons/SvgIcons";

export const SocMediaData = [
    {
        icon: <BsTwitter/>,
        iconReact: <BsTwitter/>,
        link: "https://twitter.com/STEMPDisruptor"
    },
    {
        icon: <YouTubeIcon/>,
        iconReact: <BsYoutube/>,
        link: "https://www.youtube.com/@STEM.Positive.Disruptor"
    },
    {
        icon: <LinkedinIcon/>,
        iconReact: <FaLinkedinIn/>,
        link: "https://www.linkedin.com/in/margaret-ajibode-0944984/"
    },
    {
        icon: <InstagramIcon/>,
        iconReact: <FaInstagram/>,
        link: "https://www.instagram.com/stem.positive.disruptor"
    },
    {
        icon: <FBIcon/>,
        iconReact: <BsFacebook/>,
        link: "https://www.facebook.com/STEM.Positive.Disruptor"
    },
    {
        icon: <FaTiktok/>,
        iconReact: <FaTiktok/>,
        link: "https://www.tiktok.com/@stempositivedisruptor"
    }
    



]