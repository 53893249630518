import React from 'react';
import { dataNavigationPanel } from './dataNavigationPanel';
import cl from "./NavigationPanel.module.css"
import { Link } from 'react-scroll';

const NavigationPanel = ({children, ...props}) => {
    return ( <div className={cl.nav_panel}>
        <div {...props} className={cl.nav_panel_body}>
            {dataNavigationPanel.map((item, index) => {
                return <div key={index} className={cl.nav_panel_item}>
                    <Link 
                        to={item.id} 
                        spy={true} 
                        smooth={true} 
                        offset={-96} 
                        duration={500}
                    >
                        {item.title}
                    </Link>
                </div>
            })}
        </div>
    </div>
        
    );
};

export default NavigationPanel;