import React, { useEffect, useState } from 'react';
import ContactLine from '../components/contactLine/ContactLine';
import TitleSection from '../components/sectionTitle/TitleSection';
import Footer from '../components/footer/Footer';
import BtnScrollToUp from '../components/ui/scrollToUpBtn/ScrollToUpBtn';
import Loading from '../components/ui/Loading/Loading';

const AboutUs = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false); 
    }, []);
    return (
        <div>
            {isLoading ? <Loading/> 
            :  <div className='container'>
            <TitleSection id="title_sec_about_page">
                ABOUT US  
            </TitleSection>
            <BtnScrollToUp/>
            <div className="body_about_page">
                <div className="first_sec_about_page">
                    <div className="title">
                        <h1>WHO WE ARE</h1>
                    </div>
                    <div className="block">
                        <div className="text">
                            {/* <h2>Titile of deskr</h2> */}
                            <p><span className='first_word'>Obatec </span>started off on a journey to make a difference in our clients world using technology to digital transform their people and systems and over the years we have been able to accomplish that and much more, and still do but we also felt that for us to be still relevant and still be impactful, to be able to continue to add value to our clients who span over the Private, Public and Non-for-profit sector, we had to evolve as our clients evolved and we saw there was also a need for an educational section.</p>
                        </div>
                        <ContactLine 
                            id="cnctLine_about_page_top" 
                            idBtn="cnctBtn_top" 
                            bodyBtn={"CONTACT US"}
                        >

                            We will be happy to help you 
                        </ContactLine>
                    </div>
                </div>
                <div className="second_sec_about_page">
                    <div className="title">
                        <h1>OUR CULTURE</h1>
                    </div>
                    <div className="second_block_container">
                        <div className="block">
                            <div className="text">
                                <h2>Mission Statement:</h2>
                                <p><span>We</span> Innovate to make our world more engaging and inclusive<br/>
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div 
                id="bottom_sec_about_page" 
                className="body_about_page"
            >
                <div className="third_sec_about_page">
                    <div className="title_container">
                        <div className="title">
                            <h1>VISION AND VALUE</h1>
                        </div>
                    </div>
                    
                    <div className="block">
                        <div className="text">
                            <p><span>To be</span> innovative in our approach, in our thinking and in our ways with integrity and loyalty.</p>
                        </div>
                    </div>

                    <ContactLine 
                        id="cnctLine_about_page_bottom"
                        idBtn="cnctBtn_bottom" 
                        bodyBtn={"CLICK HERE"}

                    >
                        WE ARE ALWAYS HAPPY TO HELP, JUST 
                    </ContactLine>
                </div>
                
            </div>
            <Footer/>
            </div>
            }
           
        </div>
       
    );
};

export default AboutUs;