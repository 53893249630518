import React from 'react';
import { ContactIcon, CompanyIcon, ServicesIcon, EducationIcon } from '../ui/svgIcons/SvgIcons.jsx'

export const SidebarData = [
    {
        icon: <ContactIcon/>,
        path: '/contacts'
    },
    {
        icon: <CompanyIcon/>,
        path: ''
    },
    {
        icon: <ServicesIcon/>,
        path: '/services'
    },
    {
        icon: <EducationIcon/>,
        path: '/education'
    },

]

export const MenuData = [
    {
        title: 'HOME',
        path: ''
    },
    {
        title: 'ABOUT US',
        path: '/about-us'
    },
    {
        title: 'EDUCATION',
        path: '/education'
    },
    {
        title: 'CONTACT US',
        path: '/contacts'
    },

]

