import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cl from './MyServiceBtn.module.css';
import { ServicesData } from './MyServiceBtnData';

const MyServiceBtn = () => {

    const [isMenuClicked, setIsMenuClicked] = useState(false) 

    return (
        <div className={cl.service_block} >
            {ServicesData.map((item, index) => {
                return <div className={cl.btnConteiner} key={item.name}>
                    {/* <Link to={item.link}> */}
                        <button 
                            className={cl.serviceBtn} 
                            onMouseEnter={() => {
                                setIsMenuClicked(index)
                            }}
                            onMouseLeave={() => {
                                setIsMenuClicked('')
                            }}
                        >
                            {item.name}  
                        </button> 
                    {/* </Link>  */}
                    <div style={{ display: isMenuClicked === index ? 'block' : 'none'}} className={cl.service_dropdown}>
                        <div className={cl.dropdownInfo}>
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                        </div>
                    </div>

                   
                </div>  
            })}
        </div>
    );
};

export default MyServiceBtn;