import React, { useEffect, useState } from 'react';
import ContactsForm from '../components/contactsForm/ContactsForm';
import Footer from '../components/footer/Footer';
import TitleSection from '../components/sectionTitle/TitleSection';
import Loading from '../components/ui/Loading/Loading';
import BtnScrollToUp from '../components/ui/scrollToUpBtn/ScrollToUpBtn';
import SocialMediaPanel from '../components/ui/socialMediaPanel/SocialMediaPanel';


const Contact = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false); 
    }, []);
    return (
        <>
            {isLoading ? <Loading/> 
            :
            <div className='container'>
                <div className="container_contact_page">
                    <TitleSection id='title_of_contacns_page'>
                        CONTACTS
                    </TitleSection>
                    <BtnScrollToUp/>
                    <div className="sections_of_contacts">
                        {/* <ContactFormMailGun></ContactFormMailGun> */}
                        <div className="top_blocks_of_contacts_page">
                            {/* <div className="blocks">
                                <h1>Call us</h1>
                                <a href="tel:+447951576708">44(0) 7951 576 708</a>
                            </div> */}
                            <div className="blocks">
                                <h1>Send us an email</h1>
                                    <a href="mailto:enquiries@obatec.com">enquiries@obatec.com</a>
                            </div>
                        </div>
                    </div>
    
                    <div className="divider"/>
    
                    <div className="sections_of_contacts">
                        <div className="middle_section_of_contacts">
                            <h1>OR JUST ASK US HERE</h1>
                            <ContactsForm btnId="btn_contacts_form"/>
                        </div>
                    </div>
    
                    <div className="divider"/>
    
                    <div className="sections_of_contacts">
                        <div className="bottom_section_of_contacts">
                            <h1>CONTACT US <br/>AND FOLLOW US ON SOCIAL MEDIA</h1>
                            <div className="soc_media_contacts_page">
                                <SocialMediaPanel id='soc_cont_page_id'/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div> 
            
            }
            {/* {!isLoading ? ( */}
                
            {/* ) : null} */}
        </>
        
    );
};

export default Contact;