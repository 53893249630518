import React from 'react';
import { Link } from 'react-router-dom';
import { SocMediaData } from './DataSocialMedia';
import cl from './SocialMediaPanel.module.css';

const SocialMediaPanel = ({id}) => {
    return (
        <div className={cl.social_media_section}>
            <div id={id} className={cl.social_media_block}>
                <div className={cl.social_media_left_side}>
                    <h2>follow us on social media</h2>
                </div>
                <div className={cl.social_media_right_side}>
                    {SocMediaData.map((item, index) => {
                        return <div key={index} className={cl.social_media_icons}>
                            <Link 
                                to={item.link} 
                                target="_blank" 
                                rel="noreferrer"
                            > 
                                {item.icon} 
                            </Link>
                        </div> 
                    })}
                </div>
            </div>
        </div>
    );
};

export default SocialMediaPanel;