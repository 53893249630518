import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContactLine from '../components/contactLine/ContactLine';
import Footer from '../components/footer/Footer';
import TitleSection from '../components/sectionTitle/TitleSection';
import Loading from '../components/ui/Loading/Loading';
import MyButton from '../components/ui/myButton/MyButton'
import BtnScrollToUp from '../components/ui/scrollToUpBtn/ScrollToUpBtn';
import { BIMIcon, ConsultIcon, PMIcon } from '../components/ui/svgIcons/SvgIcons';

const Services = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false); 
    }, []); 

    return (
        <div>
            {isLoading ? <Loading/> 
            : 
            <div className='container'>
            <TitleSection id='title_of_services_page'>
                SERVICES
            </TitleSection>
            <BtnScrollToUp/>
            <div className="body_services_page">
                <div className="first_section_of_services_page">
                    <div className="consultancy_sec container_sections">
                        <div className="data_sec">
                            <div className="text">
                                <h2>Digital Transformation</h2>
                                <p>Reap the benefits from a modern digital strategy, made real through expert project management, careful implementation and clear communication. Obatec provides you with a cost effective, holistic solution to meet your business needs.  We deliver real performance transformation, recognizing the changing nature of the market and customer needs, balancing growth with capital capability, and focusing on results. We will work with you to fully understand how your people, technology and process can work in harmony to support your business now and in the future.</p>
                            </div>
                            <div className="icon">
                                <ConsultIcon/>
                            </div>
                        </div> 
                        
                    </div>
                    <div className="btn_one">
                        <Link to={"/contacts"}>
                            <MyButton id="btn_contact_one">
                                CONTACT US
                            </MyButton>
                        </Link>
                    </div>
                </div>

                <div className="second_section_of_services_page">
                    <div className="bim_sec container_sections">
                        <div className="data_sec">
                            <div className="icon">
                                <BIMIcon/>  
                            </div>
                            <div className="text">
                                <h2>Change Management</h2>
                                <p>Change is an unavoidable aspect of business today. See the impacts of real change delivered from a project or technical perspective, Obatec will help and support you through the transition of change</p>
                                <Link to={"/contacts"}>
                                    <MyButton id="btn_contact_two">
                                        CONTACT US
                                    </MyButton>
                                </Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div 
                id="body_bottom_services_page" 
                className="body_services_page"
            >
                <div className="third_section_of_services_page">
                    <div className="projectM_sec container_sections">
                        <div className="data_sec">
                            <div className="text">
                                <h2>Project Management</h2>
                                <p>If you have a project, we can help. We cater to a broad range of project needs and requirements, just get in touch to tell us more.</p>
                            </div>
                            <div className="icon">
                                <PMIcon/>
                            </div>
                        </div>
                        
                    </div>
                    <div className="contact_line">
                        <ContactLine 
                            id="cntctLine" 
                            idBtn="cnctBtn" 
                            bodyBtn={"CLICK HERE"}
                        >
                            We work for our clients, so we are waiting for you, just 
                        </ContactLine>
                    </div>
                    

                </div>  
            </div>
                          
             
            <Footer/>
        </div>
        }

        </div>
        
        


        // <div className='container' id='pageSERVICE'>
        //     <TitleSection id='title_of_services_page'>
        //         SERVICES
        //     </TitleSection>
        //     {dataServicesPage.map((item, index) => {
        //         return <div key={index} className="body_services_page">
        //             <div className="first_section_of_services_page">
        //                 <div className="consultancy_container">
        //                     <div className="text">
        //                         <h2>{item.title}</h2>
        //                         <p>{item.text}</p>
        //                     </div>
        //                     <div className="icon">
        //                         {item.icon}
        //                     </div>
        //                 </div>
        //                 <div className="btn_one">
        //                     <MyButton>
        //                         CONTACT US
        //                     </MyButton>
        //                 </div>
        //             </div>

        //             <div className="second_section_of_services_page">
        //                 {/* <div className="bim_container">
        //                     <div className="text">
        //                         <h2>{item.title}</h2>
        //                         <p>{item.text}</p>
        //                         <MyButton>
        //                             CONTACT US
        //                         </MyButton>
        //                     </div>
        //                     <div className="icon">
        //                         {item.icon}
        //                     </div>
        //                 </div> */}
        //             </div>

        //             <div className="third_section_of_services_page">
        //                 {/* <div className="projectM_container">
        //                     <div className="text">
        //                         <h2>{item.title}</h2>
        //                         <p>{item.text}</p>
        //                     </div>
        //                     <div className="icon">
        //                         {item.icon}
        //                     </div>
        //                 </div> */}
        //             </div>            
        //         </div> 
        //     })}
            
            

        // </div>
    );
};

export default Services;