import React, { useEffect, useState } from 'react';
import TitleSection from '../components/sectionTitle/TitleSection';
import NavigationPanel from '../components/ui/navigationPanel/NavigationPanel';
import MyButton from '../components/ui/myButton/MyButton';
import PeriodicTable from '../components/myTable/PeriodicTable';
import Footer from '../components/footer/Footer';
import SocBubblesMedia from '../components/socBubblesMedia/SocBubblesMedia';
import BtnScrollToUp from '../components/ui/scrollToUpBtn/ScrollToUpBtn';
import Loading from '../components/ui/Loading/Loading';


const Education = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
        setIsLoading(false);
        }); 
        
        return () => clearTimeout(timer);
    }, []);
    // useEffect(() => {

    //         setIsLoading(false); 
    // }, []);
    
    return (
        <div>
            {/* {isLoading ? <div className='loading_wait'><span>LOADING...</span></div> : null} */}
            {isLoading ? <Loading/> 
            : <div className='container'>
            <TitleSection id="title_education_page">
                EDUCATION
            </TitleSection>
            {/* <div className="nav_panel_container"> */}
                <NavigationPanel/>
            {/* </div> */} 
            <BtnScrollToUp/>
            <div className="body_podcast_educ_page">
                <TitleSection id='educ_page_title_podcast'>
                    PODCAST
                </TitleSection>
                <div className="mainData">
                    <h1>Watch our podcast...</h1>
                    <div className="video_audio_data">
                        <div className="left_side_video">
                            <a href="https://www.youtube.com/@STEM.Positive.Disruptor" target="_blank" rel="noreferrer">
                                <img 
                                    src='https://logos-world.net/wp-content/uploads/2020/04/YouTube-Logo.png' 
                                    alt="You Tube" 
                                />
                            </a>
                        </div>
                        <div className="right_side_audio">
                            <h1>OR YOU CAN JUST LISTEN</h1>
                            <div className="audio_link">
                                <h1>HERE</h1>
                                <a href="https://open.spotify.com/show/0gIDFW8jAGB6aFNs4YsQq4" target="_blank" rel="noreferrer">
                                    <MyButton id="btn_to_audio">
                                        AUDIO
                                    </MyButton>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="discr_for_aud_video">
                        <p>This platform is to Educate, Raise awareness, Demystify and Change Perception about STEM (Science, Technology, Engineering and Mathematics) and make it more accessible to the public. Show the diverse roles, the benefits and impact of STEM in our world. <br/>We have special guests on, to have a conversation to bring change and provide practical solutions to combat world issues such as climate change, pandemic, skills shortage and anything else they feel passionate</p>
                    </div>
                    
                </div>
            </div>

            <TitleSection id='educ_page_title_table'>
                TABLE
            </TitleSection>
            <div className="body_table_educ_page">

                <div className="descript_table">
                    <p> <span className='paragraf'>Science Technology Engineering and Mathematics (STEM) Hall of Fame</span><br/>
                    The periodic table of chemical elements, created by Dmitry Mendeleev in 1869, is one of the most important achievements in modern science. To celebrate this achievement, Obatec has created our own periodic table! Instead of elements. Our mission is to showcase the diversity of roles, recognize and celebrate the diversity of people from across the globe who have contributed and impacted our world through their contribution to and using STEM excellence in our world from the past, present and future. Known and unknown, Non-Disabled and Disabled, trailblazers dedicated to making positive difference, in education, industry, government and media either through innovation, invention, advocacy bringing our vision to educate, raise awareness, change perception, demystify and inspire through the power of STEM<br/>
                    We intentionally foster a diverse and equitable periodic table that encourages and embraces creativity and innovation.<br/>
                    Every year peers will nominate those who should be inducted and the public will vote.<br/>
                    
                    </p>
                    <p className='nomination'>Nomination for year 2023 is closed.</p>
                </div>
                <div className="section_for_table">
                    <PeriodicTable/>
                    <h3 className='thanks_text_for_table'>* Obatec would like to thank US Bureau Labor of Statistics for the original idea for this table.</h3>
                </div>
            </div>
            
            <TitleSection 
                id='educ_page_title_gameApp'
                style={{ display: 'none'}}
            >
                GAME APP
            </TitleSection>
            <div className="game_and_soc_media_page">

                <div 
                    className="body_game_app_page"
                    style={{ display: 'none'}}
                >
                    <div className="game_app_descr">
                        <h1>UNDER DEVELOPMENT ...</h1>
                        <p>The Game is ... diskr... exmpl text donec semper lorem lorem, nec volutpat neque tempor in. Curabitur metus lorem, rhoncus et arcu at, vehicula vehicula massa. Vivamus sit amet sodales lorem, ac sodales justo. Phasellus imperdiet vitae leo ac finibus. Duis consectetur, libero ut dictum faucibus, sem velit cursus dui, eget suscipit nulla orci eu sem.</p>
                    </div>
                    <div className="game_app_platforms_container">
                        <div className="left_side">
                            <h2>
                                CHOOSE YOUR OPERATING SYSTEM
                            </h2>
                        </div>
                        <div className="right_side">
                            <MyButton id='iOSPlatform'>iOS</MyButton>
                            <MyButton id='androidPlatform'>ANDROID</MyButton>
                        </div>
                    </div>
                </div>
                
                <TitleSection id='educ_page_title_socMedia'>
                    SOCIAL MEDIA
                </TitleSection>
                <div className="body_soc_media_page">
                    <div className="title">
                        <h1>FOLLOW US ON SOCIAL MEDIA</h1>
                    </div>
                    <SocBubblesMedia></SocBubblesMedia>
                </div>
            </div>
            
            <Footer/>
        </div>
        }
            
                

        </div>
        
    );
};

export default Education;
