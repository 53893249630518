import React from 'react';
import cl from './loading.module.css';

const Loading = () => {
    return (
        // <div className={cl.loading_container}>
        //     <div className={cl.loading}></div>
        // </div>

        //--------- load 2
        // <div className={cl.loading}>
        //     <div className={cl.loading_circle}></div>
        //     <div className={cl.loading_text}>Loading...</div>
        // </div>

        //--------- load 3
        <div className={cl.hexagon_container}>
            <div className={cl.hexagon}></div>
            <div className={cl.hexagon_text}>OBATEC...</div>
        </div>
        
    );
};

export default Loading;