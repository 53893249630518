import React from 'react';
import cl from './SocBubblesMedia.module.css';
import { dataSocBubble } from './dataSocBubble';

const SocBubblesMedia = () => {
    return (
        <div className={cl.soc_media_bubbles_container}>
            {dataSocBubble.map((bubble) => {
                return <a 
                key={bubble.title}
                href={bubble.link} 
                target="_blank" 
                rel="noreferrer"
                className={cl.bubbles_href}
            >
                <div className={cl.container_bubbles}>
                    <div 
                        className={cl.circle_bubbles}
                        style={{
                            background: bubble.color
                        }}

                    >
                        {bubble.icon}
                    </div>
                    <h2>{bubble.title}</h2>
                </div>
            </a>
            })}
            
        </div>
    );
};

export default SocBubblesMedia;