import { useState, useEffect } from "react";
import { BsArrowUpCircle, BsArrowUpShort } from "react-icons/bs";
import cl from './ScrollToUpBtn.module.css';

function BtnScrollToUp({...props}) {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrolledPercentage =
        (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
      if (scrolledPercentage >= 20) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div {...props} className={cl.brn_scroll_to_up_container}>
      {showScrollButton && (
        <BsArrowUpShort className={cl.arrow_up} onClick={handleScrollTop}/>
      )}
      {/* rest of the page */}
    </div>
  );
}

export default BtnScrollToUp;