import React, { useEffect, useState } from 'react';
// import { FaBars } from 'react-icons/fa';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cl from './BurgerMenuSmallSc.module.css';
import { SocMediaData } from '../ui/socialMediaPanel/DataSocialMedia';
import { Link } from 'react-router-dom';
import { smallMenuData } from './dataBurgerMenuSmallSc';
import BurgerMenu from '../ui/burgerMenu/BurgerMenu';
import { CompanyIcon, IconOfCompany } from '../ui/svgIcons/SvgIcons';

const BurgerMenuSmallSc = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [burgerClass, setBurgerClass] = useState(`burger_bar unclicked`)
  
  const toggleMenu = () => {
    if(!isOpen) {
      setBurgerClass('burger_bar clicked')
    } else {
      setBurgerClass('burger_bar unclicked')
    }
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
        body.classList.add('no-scroll');
    } else {
        body.classList.remove('no-scroll');
    }
  }, [isOpen]);

 
  return (
    <div className={cl.burger_small_screen_body}>
      {/* <FaBars className={cl.burger_sticks} onClick={toggleMenu} /> */}
      
      <div className={cl.btns_logo_sticks_body}>
        <Link to={'/'}>
          {/* <IconOfCompany/> */}
          <CompanyIcon/>
        </Link>
        
        <BurgerMenu id={cl.btn_sticks} item={burgerClass} onClick={toggleMenu}/>
      </div>
      
      <TransitionGroup>
        {isOpen && (
          <CSSTransition classNames="menu" timeout={200}>
            <div className={cl.menu}>
                <div className={cl.menu_container}>
                    <ul className={cl.menuList}>
                        {smallMenuData.map((item) => {
                            return <li 
                              key={item.path} 
                            >
                            <Link
                              className={cl.items} 
                              to={item.path}
                              onClick={toggleMenu}
                            >
                              {item.title}
                            </Link>
                            </li>
                        })} 
                    </ul>

                    <div className={cl.socMedia}>
                        {SocMediaData.map((item, index) => {
                            return <div key={index} className={cl.icons}>
                                <a href={item.link} target="_blank" rel="noreferrer" >{item.iconReact}</a>
                            </div> 
                        })}
                    </div>
                </div>
                
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default BurgerMenuSmallSc;