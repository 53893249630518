import React, { useState, useEffect } from 'react';
// import PeriodicItem from './PeriodicItem';
import cl from './PeriodicTable.module.css';
import { dataPeriodicTable, dataItemsInfo } from './dataPeriodicTable.jsx';
import PeriodicItem from './PeriodicItem';
import { Link } from 'react-scroll';


const colorList = [
    {color: '#A84B4B', name: 'Engineering'},
    {color: '#3FABA5', name: 'Chemistry', category: 'Chemistry'},
    {color: '#813F89', name: 'Environmental Science'},
    {color: '#648037', name: 'Geoscience', category: 'Geoscience'},
    {color: '#D28BBE', name: 'STEM', category: 'STEM'},
    {color: '#387BBA', name: 'Mathematics', category: 'Mathematics'},
    {color: '#FFC531', name: 'Computer Science', category: 'ComputerScience'},
    {color: '#6D557F', name: 'Emerging Technologies', category: 'EmergingTechnologies'},
    {color: '#F19F53', name: 'Physics', category: 'Physics'},
    {color: '#2D3BBF', name: 'Life Sciences'},
]

const textColor = {
    'ComputerScience': '#171717',
    'Physics': '#171717',
    'Chemistry': '#171717',
    'STEM': '#171717',
}


// const ItemOfTable = ({ color, isHovered, onMouseEnter, onMouseLeave, name, title, xpos, ypos, fontColor, id}) => {
//     const opacity = isHovered ? 0.5 : 1;
//     // const opacity = isHovered ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.1)';
//     const style = {
//         backgroundColor: color, 
//         color: fontColor,
//         opacity: opacity,        
//         gridColumn: xpos,
//         gridRow: ypos,
//     }
//     return (
//         <div 
//             id={id}
//             onMouseEnter={onMouseEnter} 
//             onMouseLeave={onMouseLeave}
//             className={cl.body_item_of_table} 
//             style={style}
//         >
//             <div className={cl.middle_sec_item}>
//                 <h1>{name}</h1>
//             </div>
//             <div className={cl.bottom_sec_item}>
//                 <p>{title}</p>
//             </div>

//         </div>
                        
                    
       
//     );
// }

const PeriodicTable = () => { 
    const [descrItem, setDescrItem] = useState(`${cl.body_item_descriptions}`); 
    const [info, setInfo] = useState(1);

    const [hoveredColor, setHoveredColor] = useState(null);
    // useEffect(() => {
    //     setInfo(1)
    // }, [])
    // const handleClick = (itemId) => {
    //     setDescrItem(itemId)
    // }
    
    const handleBlockHover = (color) => {
        setHoveredColor(color);
    }

    function handleBlockLeave() {
        setHoveredColor(null);
    }

    return (
        <div className={cl.main_body_of_table}>
            <div className={cl.cubes_left_side}>

                <div className={cl.item_groups_small}>
                    <h1>GROUPS</h1>
                    <ul className={cl.groups_list_small}>
                        {colorList.map((it) => {
                            return <div key={it.name} 
                                className={cl.groups_list_body_small}
                                onMouseEnter={() => handleBlockHover(it.color)}
                                onMouseLeave={handleBlockLeave}    
                            >
                                <li 
                                    style={{
                                        background: it.color,
                                        opacity: hoveredColor && it.color !== hoveredColor ? 0.5 : 1,
                                    }}
                                    
                                />
                                <p>{it.name}</p>
                            </div>
                            

                        })}
                    </ul>
                </div>

                <div className={cl.table_item}>
                    
                    {dataPeriodicTable.map((item) => {
                        return <Link
                            key={item.name} 
                            to={cl.info_right_side} 
                            spy={true} 
                            smooth={true} 
                            offset={-100} 
                            duration={500}
                        >
                            <div 
                                
                                id={item.id}
                                color={item.color}
                                className={cl.body_item_of_table} 
                                style={{
                                    // gridColumn: item.xpos,
                                    // gridRow: item.ypos,
                                    backgroundColor: item.color, 
                                    opacity: hoveredColor && item.color !== hoveredColor ? 0.3 : 1, 
                                    color: textColor[item.category],
                                }}
                                onClick={() => {
                                    setInfo(item.id)
                            
                                }}
                                onMouseEnter={() => handleBlockHover(item.color)}
                                onMouseLeave={handleBlockLeave}
                                
                                
                            >
                    
                                <div className={cl.middle_sec_item}>
                                    <h1>{item.name}</h1>
                                </div>
                                <div className={cl.bottom_sec_item}>
                                    <p>{item.title}</p>
                                </div>

                            </div>
                        </Link>
                        
                    })}
                
                    
                    <div className={cl.item_groups_names}>
                        <h1>GROUPS</h1>
                        <ul className={cl.groups_names_list}>
                            {colorList.map((it) => {
                                return <li key={it.name} style={{
                                        backgroundColor: it.color,
                                        opacity: hoveredColor && it.color !== hoveredColor ? 0.5 : 1,
                                        color: textColor[it.category],
                                    }}
                                    onMouseEnter={() => handleBlockHover(it.color)}
                                    onMouseLeave={handleBlockLeave}
                                >
                                    {it.name}
                                </li>
                                
                            })}
                        </ul>
                    </div>
                </div>
    
            </div>
            
            {/* <div className={cl.container_right_side}> */}
                <div className={cl.info_right_side}>
                    <div
                        id={dataItemsInfo[info - 1].id} 
                        className={descrItem}
                    > 
                        {/* <h1>{dataItemsInfo[info - 1].blockName}</h1> */}
                        <h1>Nominees and inductees for the year 2023</h1>
                        
                        <h2 className={cl.title_of_item_top}>{dataPeriodicTable[info - 1].title}</h2>
                        <div className={cl.item_past_descr}>
                            {/* <h2>{dataItemsInfo[info - 1].blockTitleNominees}</h2> */}
                            <h2>Nominees:</h2>
                            {/* <ul>
                                <li><h3>{dataItemsInfo[info - 1].blockDescriptionNominees}</h3></li>
                            </ul> */}
                            {dataItemsInfo[info - 1].blockDescriptionNominees}
                        </div>
                        <div className={cl.item_present_descr}>
                            {/* <h2>{dataItemsInfo[info - 1].blockTitleInductees}</h2> */}
                            <h2>Inductees:</h2>
                            <p>
                                {dataItemsInfo[info - 1].blockDescriptionInductees}
                            </p>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            
        </div>
    );
};

export default PeriodicTable;