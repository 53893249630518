import React, { useEffect, useState } from 'react';
import { SidebarData, MenuData } from './SidebarData';
import { Link } from "react-router-dom";
import BurgerMenu from '../ui/burgerMenu/BurgerMenu';
import { SocMediaData } from '../ui/socialMediaPanel/DataSocialMedia';

const Sidebar = () => {

    const [burgerClass, setBurgerClass] = useState('burger_bar unclicked')
    const [menuClass, setMenuClass] = useState('menu hidden')
    const [shadowClass, setShadowClass] = useState('bg_shadow hidden')
    const [isMenuClicked, setIsMenuClicked] = useState(false) 
    
    
    useEffect(() => {
    const body = document.querySelector('body');
    if (isMenuClicked) {
        body.classList.add('no-scroll');
    } else {
        body.classList.remove('no-scroll');
    }
    }, [isMenuClicked]);
    
    const updateMenu = () => {

        if(!isMenuClicked) {
            setBurgerClass('burger_bar clicked')
            setMenuClass('menu visible')
            setShadowClass('bg_shadow visible')

        } else {
            setBurgerClass('burger_bar unclicked')
            setMenuClass('menu hidden')
            setShadowClass('bg_shadow hidden')
        }
        setIsMenuClicked(!isMenuClicked)
    }

    return <div className="container_sidebar">
        <div className='burger_menu_sidebar_btn'>
            <BurgerMenu 
                item={burgerClass} 
                onClick={updateMenu}
            />
        </div>
        <div className='sidebar'>
            <ul className='sidebarList'>
                {SidebarData.map((item) => {
                    return <li 
                        key={item.path} 
                    >
                    <Link
                        className='items' 
                        to={item.path}
                    >
                        {item.icon}
                    </Link>
                    </li>
                })} 
            </ul>

        </div>
        <div 
            className={shadowClass} 
            onClick={updateMenu}>

            <div className={menuClass} onClick={(e) => e.stopPropagation()}>
                <div className='menuWindow'>
                    <ul className='menuList'>
                        {MenuData.map((item) => {
                            return <li 
                                key={item.path} 
                            >
                            <Link
                                className='items' 
                                to={item.path}
                                onClick={updateMenu}
                            >
                                {item.title}
                            </Link>
                            </li>
                        })} 
                    </ul>

                    <div className="socMedia">
                        {SocMediaData.map((item, index) => {
                            return <div key={index} className="icons">
                                <a href={item.link} target="_blank" rel="noreferrer" >{item.iconReact}</a>
                            </div> 
                        })}
                    </div>

                </div>
                
            </div>
        </div>
    </div>
    
    
}





export default Sidebar;