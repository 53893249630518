



import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import MyButton from '../ui/myButton/MyButton';
import cl from './ContactsForm.module.css';

const ContactsForm = ({btnId}) => {
    const [dataS, setData] = useState({name: '', email: '', phone: '', subject: '', message: ''})
    
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({...dataS, [name]: value})
    } 
    const [loading, setLoading] = useState(false);

    const isFieldFilled = dataS.name.trim() !== '';
    const inputClass = isFieldFilled ? '' : `${cl.error}`;

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!dataS.name || !dataS.email || !dataS.subject || !dataS.message) {
            return toast.error('You forgot to fill in all required fields');
        }
        try {
            setLoading(true);
            const { data } = await axios.post(`https://obatec.com/api/email`, {
                dataS
            }); 
            setLoading(false);
            toast.success(data.message);
        } catch (err) {
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message || 'An error occurred');
            }
        }
        setData({name: '', email: '', phone: '', subject: '', message: ''})
    }
    
    return (
        <div>
            <ToastContainer position='bottom-center' limit={1}/>
            <form 
                className={cl.contac_form}
                // onSubmit="var originalCharset = document.charset; document.charset = 'ISO-8859-1'; window.onbeforeunload = function () {document.charset=originalCharset;};">
                onSubmit={submitHandler}
                >
                <div id={cl.top_section} className={cl.section_form}>
                    <div className={cl.left_side}>
                        <label htmlFor="name">Name <span className={cl.red_star}>*</span></label>
                        {/* <h3>Name <span className={cl.red_star}>*</span></h3> */}
                        <input 
                            type='text' 
                            name="name" 
                            id=''
                            value={dataS.name} 
                            onChange={handleChange}
                            className={inputClass}
                            // onChange={(e) => setName(e.target.value)} 
                            placeholder='Your full name...'
                        />
                    </div>
                    <div className={cl.right_side}>
                        <label htmlFor="email">Email <span className={cl.red_star}>*</span></label>
                        {/* <h3>Email <span className={cl.red_star}>*</span></h3> */}
                        <input 
                            type='email' 
                            name="email" 
                            id='' 
                            value={dataS.email} 
                            onChange={handleChange}
                            // onChange={(e) => setEmail(e.target.value)}
                            placeholder='example@gmail.com'
                        />
                    </div>
                </div>

                <div id={cl.middle_section} className={cl.section_form}>
                    <div className={cl.left_side}>
                        <label htmlFor="phone">Phone</label>
                        {/* <h3>Phone</h3> */}
                        <input 
                            type='phone' 
                            name="phone" 
                            id=''
                            value={dataS.phone} 
                            onChange={handleChange}
                            // onChange={(e) => setPhone(e.target.value)} 
                            placeholder='+44 7373 7373 73'
                        />
                    </div>
                    <div className={cl.right_side}>
                        <label htmlFor="subject">Subject <span className={cl.red_star}>*</span></label>
                        {/* <h3>Subject</h3> */}
                        <input 
                            type='text' 
                            name="subject" 
                            id=''
                            value={dataS.subject} 
                            onChange={handleChange}
                            // onChange={(e) => setSubject(e.target.value)} 
                            placeholder='Subject...'
                        />
                    </div>
                </div>

                <div className={cl.bottom_section_form}>
                    <label htmlFor="message">Message <span className={cl.red_star}>*</span></label>
                    {/* <h3>Message <span className={cl.red_star}>*</span></h3> */}
                    <textarea 
                        name='message' 
                        id='' 
                        value={dataS.message} 
                        onChange={handleChange}
                        // onChange={(e) => setMessage(e.target.value)}
                        cols={40} 
                        rows={5} 
                        placeholder='Your message for us...'
                    />
                </div>

                <div className={cl.btn_form}>
                    <MyButton disabled={loading} type='submit' id={cl.btnId}>
                        {loading ? 'SENDING...' : 'SUBMIT'}
                    </MyButton>
                </div>
            </form>
        </div>
    );
};

export default ContactsForm;
