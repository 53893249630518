import React from 'react';
import cl from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className={cl.footer_container}> 
                <div className={cl.footer_body}>
                    <div className={cl.footer_left_side}>
                        <h1>Accessibility</h1>
                        <p>Obatec is committed to accessibility and making our website accessible to the wide range of possible audience, regardless of disability, capability or technology.</p>
                    </div>
                    <div className={cl.footer_right_side}>
                        <div className={cl.right_side_body}>
                            <Link to='/services'><h2 id={cl.serv}>SERVICES</h2></Link>
                            <Link to='/education'><h2 id={cl.educ}>EDUCATION</h2></Link>
                            <Link to='/contacts'><h2 id={cl.cntct}>CONTACT US</h2></Link>
                            <h3>© Obatec Ltd 2023</h3>
                        </div>
                    </div>
                </div>
        </footer>
    );
};

export default Footer;