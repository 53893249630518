import React from "react"
import { AiOutlineUser } from "react-icons/ai";
import imageM from "../../images/userMMM.png";
import imageMargaret from "../../images/Margaret_Ajibode_for_website.JPG"
export const MyCardsData = [
    { 
        icon: <AiOutlineUser/>,
        img: imageMargaret,
        name: 'Margaret Ajibode CEng, FBCS',
        title: 'Founder',
        text: 'Is a business owner and Chartered Engineer with over 20 years’ experience in digitally transforming businesses across the Private, Public and Not-for-Profit sector, she thrives in a fast paced, multi-cultural environment, comfortable working with diverse people on multiple projects, including infrastructure, systems integration, business Continuity planning, change management, Strategic Planning and Project Management. Passion for finding ways to improve the way we do things, increase productivity and profit margin, enthusiastic and a proven ability to get things done on time and within budget. Director of the International Network of Women Engineers and Scientists (INWES), Co-Chair Programme and Project Committee for 2011- 2014, Co-Chair Advocacy Committee & Secretary General 2014 - 2017 STEM Ambassador since 2008, National Science and Engineering (Big Bang) Judge, Chief Judge for the London Region and South East Big Bang Regional Chair Engineering & Technology Stream till 2020 Judge at the UK IT Industry Award 2017 and at Women in IT Excellence Award'
    },
    // {
    //     icon: <AiOutlineUser/>,
    //     img: imageTest,
    //     name: 'Somebody',
    //     title: 'Chartered Engineer and Owner',
    //     text: 'Started Obatec in 2005 to help businesses use technology more efficiently and effectively in their environment.  y and effectively in their environment. Started Obatec in 2005 to help businesses use technology more efficiently and effectively in their environment.  y and effectively in their environment. Started Obatec in 2005 to help businesses use technology more efficiently and effectively in their environment.  y and effectively in their environment. Started Obatec in 2005 to help businesses use technology more efficiently and effectively in their environment.  y and effectively in their environment.'
    // },
    // {
    //     icon: <AiOutlineUser/>,
    //     img: imageM,
    //     name: 'Somebody again',
    //     title: 'Chartered Engineer and Owner',
    //     text: 'Started Obatec in 2005 to help businesses use technology more efficiently and effectively in their environment.'
    // }

]