import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MyCards from '../components/cards/MyCards';
import Footer from '../components/footer/Footer';
import TitleSection from '../components/sectionTitle/TitleSection';
import MyServiceBtn from '../components/ui/btnServices/MyServiceBtn';
import MyButton from '../components/ui/myButton/MyButton';
import SocialMediaPanel from '../components/ui/socialMediaPanel/SocialMediaPanel';
import BtnScrollToUp from '../components/ui/scrollToUpBtn/ScrollToUpBtn';
import Loading from '../components/ui/Loading/Loading';
// import { ServicesData } from './MyServiceBtnData';


const MainPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false); 
    }, []);
    return (
        <div>
            {isLoading ? <Loading/> 
            : 
            <div className='container'>
                <BtnScrollToUp/>
                <div className="first_part_of_page">
                    {/* <TestTestTest/> */}
                    <div className="top_section">
                        <div className="left_side">
                            <h1>OBATEC</h1>
                        </div>
                        <div className="right_side">
                            <div className="top_sec">
                                <h1>We can help transform you</h1>
                            </div>
                            <div className="bottom_sec">
                                {/* <h1>JUST</h1> */}
                                
                                <Link style={{color: '#171717'}} to='/contacts'>
                                    <MyButton id="btn">
                                        CLICK HERE
                                    </MyButton>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                    {/* <ContactFormTest/> */}
    
                    <div className="middle_section">
                        <div className="main_services_of_co">
                            <div className="main_services_body">
                                <h1>Consultancy and Project Management Services</h1>
                                <p>We provide engineering consultancy, project management in the areas of construction (BIM), Digital Transformation, Change Management, Infrastructure, Business continuity planning and strategy. It is hearing your business challenges and goals, and creating the right plan of action to help and support you. It is important for us to develop deep, meaningful and ultimately long-term relationships with our clients so we can be more effective in terms of improving what we do for them by gaining a greater understanding of who they are and delivering what they need.</p>
                            </div>
                            
                        </div>
                        <div className='services_buttons_middle'> 
                            <MyServiceBtn/>
                        </div>
                        <div className="link_to_services_fp">
                            <div className="link_to_sp_container">
                                <div className="title_sp_container">
                                    <h3>Check what services we provide</h3>
                                </div>
                                <div className="btn_to_sp">
                                    <Link to={'/services'}>
                                        <MyButton id="btn_to_sp_id">
                                            HERE
                                        </MyButton>
                                    </Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
    
                    
    
                    <div className="bottom_section">
                        <SocialMediaPanel id='social_media_first_part_mainP'/>
                    </div>
                </div>
                
    
                <div className="second_part_of_page">
                    <div className="blocks_of_second_page">
                        <div className="top_block_of_second_page">
                            <h1>We are happy to help you</h1>
                        </div>
                        <div className="middle_block_of_second_page">
                            <Link to='/contacts'>
                                <MyButton id="btn_contact">
                                    CONTACT US
                                </MyButton>
                            </Link>
                        </div>
                        <div className="bottom_block_of_second_page">
                            <div className="title">
                                <h1>
                                    Learn more in the <span><Link style={{color: '#88398B',cursor: 'pointer'}} to='/about-us'>About Us</Link></span> and <span><Link style={{color: '#FFC531',cursor: 'pointer'}} to='/education'>Education</Link></span> sections
                                </h1>
                            </div>
                            <div className="block_btns_to_page">
                                <div className="bnts_to_pages">
    
                                    <Link to='/about-us'>
                                        <MyButton id="btn_about">
                                            ABOUT US
                                        </MyButton>
                                    </Link>
    
                                    <Link to='/education'>
                                        <MyButton id="btn_education">
                                            EDUCATION
                                        </MyButton>
                                    </Link>
    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                <div className="third_part_of_page">
                    <TitleSection id='our_team_section'>
                        OUR TEAM
                    </TitleSection>
                    <div className="under_top_section_of_third_page">
                        <div className="text_info">
                            <h3>We are progressive in our thinking and want you to have a great experience</h3>
                        </div>
                    </div>
                    <div className="middle_section_of_third_page">
                        <MyCards/>
                    </div>
                    <div className="under_middle_section_of_third_page">
                        <div className="text_info">
                            <h2>Our team is very effective in delivering because we are professional in our approach, think progressively and our focus is you.</h2>
                        </div>
                    </div>
                    <div className="bottom_section_third_part">
                        <SocialMediaPanel id='social_media_third_part'/>
                    </div>
                </div>
                
                <Footer/>
            </div>
            }
            
        </div>
        
    );
};

export default MainPage;