
export const ServicesData = [
    {
        name: 'Digital Transformation',
        title: '',
        link: '/services',
        text: 'Reap the benefits from a modern digital strategy, made real through expert project management, careful implementation and clear communication.'
    },
    {
        name: 'Change Management',
        title: '',
        link: '/services',
        text: 'Change is an unavoidable aspect of business today. See the impacts of real change delivered from a project or technical perspective, Obatec will help and support you through the transition of change.'
    },
    {
        name: 'Project Management',
        title: '',
        link: '/services',
        text: 'If you have a project, we can help. We cater to a broad range of project needs and requirements, just get in touch to tell us more.'
    }
]