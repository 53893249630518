export const smallMenuData = [
    {
        title: 'HOME',
        path: ''
    },
    {
        title: 'ABOUT US',
        path: '/about-us'
    },
    {
        title: 'SERVICES',
        path: '/services'
    },
    {
        title: 'EDUCATION',
        path: '/education'
    },
    {
        title: 'CONTACT US',
        path: '/contacts'
    },
]