import React from 'react';
import cl from './BurgerMenu.module.css';

const BurgerMenu = ({item, onClick, ...props}) => {

    return (
        <div {...props} className={cl.burger_menu} onClick={onClick}>
            <div id={cl.burger_b} className={item}></div>
            <div id={cl.burger_b} className={item}></div>
        </div>
    );
};

export default BurgerMenu;