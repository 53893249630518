import { FBIcon, InstagramIcon, LinkedinIcon, TiktokIcon, TwitterIcon, YouTubeIcon } from "../ui/svgIcons/SvgIcons";
import { FaTiktok } from "react-icons/fa";
// import { BsTwitter } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";

export const dataSocBubble = [
    {
        link: 'https://twitter.com/STEMPDisruptor',
        icon: <TwitterIcon/>,
        title: 'TWITTER',
        color: '#387BBA'
    },
    {
        link: 'https://www.youtube.com/@STEM.Positive.Disruptor',
        icon: <YouTubeIcon/>,
        title: 'YOUTUBE',
        color: '#A84B4B'
    },
    {
        link: 'https://www.linkedin.com/in/margaret-ajibode-0944984/',
        icon: <LinkedinIcon/>,
        title: 'LINKEDIN',
        color: '#914199'
    },
    {
        link: 'https://www.instagram.com/stem.positive.disruptor',
        icon: <InstagramIcon/>,
        title: 'INSTAGRAM',
        color: '#3F8089'
    },
    {
        link: 'https://www.facebook.com/STEM.Positive.Disruptor',
        icon: <FBIcon/>,
        title: 'FACEBOOK',
        color: '#648037'
    },
    {
        link: 'https://www.tiktok.com/@stempositivedisruptor', 
        icon: <TiktokIcon/>,
        title: 'TIKTOK',
        color: '#D79B03'
    },
]