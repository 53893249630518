import AboutUs from "../pages/AboutUs";
import Contact from "../pages/Contact";
import Education from "../pages/Education";
import MainPage from "../pages/MainPage";
import Services from "../pages/Services";

export const publicRoutes = [
    {path: '/main', element: <MainPage/>, exact:true},
    {path: '/contacts', element: <Contact/>, exact:true},
    {path: '/education', element: <Education/>, exact:true},
    {path: '/services', element: <Services/>, exact:true},
    {path: '/about-us', element: <AboutUs/>, exact:true},
    {path: '*', element: <MainPage/>, exact:true}
] 