export const dataPeriodicTable = [
    {
        degree: 'Bachelor’s',
        num: 1,
        name: 'Ce',
        title: 'Chemical engineers',
        id: 1,
        xpos: 1,
        ypos: 1,
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 2,
        name: 'Ch',
        title: 'Chemists medicinal, chemist pharmacology',
        id: 2,
        xpos: 4,
        ypos: 1,
        category: 'Chemistry',
        color: '#3FABA5',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 3,
        name: 'Ct',
        title: 'Chemical technicians, Chemical, process and energy, analytical chemist, research chemist etc.',
        id: 3,
        xpos: 5,
        ypos: 1,
        category: 'Chemistry',
        color: '#3FABA5',
        fontColor: '#171717',

    },
    {
        degree: 'Bachelor’s',
        num: 4,
        name: 'CEt',
        title: 'Civil engineering technologists, Construction Architecture, building, and planning',
        id: 4,
        xpos: 6,
        ypos: 1,
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 13,
        name: 'Ep',
        title: 'Epidemiologists',
        id: 13,
        xpos: 2,
        ypos: 5,
        category: 'LifeSciences',
        color: '#2D3BBF',

    },
    {
        degree: 'Bachelor’s',
        num: 6,
        name: 'Ft',
        title: 'Food science and Technology',
        id: 6,
        xpos: 5,
        ypos: 2,
        category: 'EnvironmentalScience',
        color: '#813F89',

    },
    {
        degree: 'Bachelor’s',
        num: 7,
        name: 'Ght',
        title: 'Geology, Geological and hydrologic technicians',
        id: 7,
        xpos: 1,
        ypos: 3,
        category: 'Geoscience',
        color: '#648037',

    },
    {
        degree: 'Bachelor’s',
        num: 8,
        name: 'Ma',
        title: 'Mathematicians',
        id: 8,
        xpos: 2,
        ypos: 3,
        category: 'Mathematics',
        color: '#387BBA',

    },
    {
        degree: 'Bachelor’s',
        num: 9,
        name: 'Ac',
        title: 'Actuaries, Statisticians',
        id: 9,
        xpos: 3,
        ypos: 3,
        category: 'Mathematics',
        color: '#387BBA',

    },
    {
        degree: 'Bachelor’s',
        num: 10,
        name: 'Me',
        title: 'Mechanical, Production and manufacturing, Aerospace',
        id: 10,
        xpos: 4,
        ypos: 3,
        category: 'Engineering',
        color: '#A84B4B',

    },
    {
        degree: 'Bachelor’s',
        num: 11,
        name: 'En',
        title: 'Environmental scientists and specialists, including health',
        id: 11,
        xpos: 5,
        ypos: 3,
        category: 'EnvironmentalScience',
        color: '#813F89',

    },

    {
        degree: 'Bachelor’s',
        num: 12,
        name: 'Hy',
        title: 'Hydrologists',
        id: 12,
        xpos: 1,
        ypos: 4,
        category: 'Geoscience',
        color: '#648037',

    },
    {
        degree: 'Bachelor’s',
        num: 13,
        name: 'Ib',
        title: 'Biotechnology, Biological sciences, Radiography and medical technology',
        id: 13,
        xpos: '',
        ypos: '',
        category: 'ComputerScience',
        color: '#FFC531',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 14,
        name: 'Ge',
        title: 'General',
        id: 14,
        xpos: '',
        ypos: '',
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 15,
        name: 'Oe',
        title: 'Ocean Engineering, Marine Engineering include Naval architecture ',
        id: 15,
        xpos: '',
        ypos: '',
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 16,
        name: 'El',
        title: 'Electronic and Electrical Engineering',
        id: 16,
        xpos: '',
        ypos: '',
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 17,
        name: 'Mec',
        title: 'Materials science, Metallurgy, Ceramics and glass, Polymers and textiles',
        id: 17,
        xpos: '',
        ypos: '',
        category: 'Engineering',
        color: '#A84B4B',
    },
    {
        degree: 'Bachelor’s',
        num: 18,
        name: 'Is',
        title: 'Computer and information systems managers, Computer network architects',
        id: 18,
        xpos: 3,
        ypos: 4,
        category: 'ComputerScience',
        color: '#FFC531',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 19,
        name: 'Ret',
        title: 'Robotics',
        id: 19,
        xpos: '',
        ypos: '',
        category: 'EmergingTechnologies',
        color: '#6D557F',
    },
    {
        degree: 'Bachelor’s',
        num: 20,
        name: 'Nt',
        title: 'Nano Technology, Artificial Intelligence, educational technologies and etc.',
        id: 20,
        xpos: '',
        ypos: '',
        category: 'EmergingTechnologies',
        color: '#6D557F',
    },
    {
        degree: 'Bachelor’s',
        num: 21,
        name: 'Ds',
        title: 'Digital art and animation, Game design, Design Technology',
        id: 21,
        xpos: 4,
        ypos: 4,
        category: 'ComputerScience',
        color: '#FFC531',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 22,
        name: 'So',
        title: 'Soil and plant scientists',
        id: 22,
        xpos: 5,
        ypos: 4,
        category: 'LifeSciences',
        color: '#2D3BBF',

    },
    {
        degree: 'Bachelor’s',
        num: 23,
        name: 'Ph',
        title: 'Physicists',
        id: 23,
        xpos: 1,
        ypos: 5,
        category: 'Physics',
        color: '#F19F53',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 24,
        name: 'Ed',
        title: 'Educators, Teachers, (preschool upwards)',
        id: 24,
        xpos: '',
        ypos: '',
        category: 'STEM',
        color: '#D28BBE',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 25,
        name: 'Cb',
        title: 'biochemistry forensics ecology',
        id: 25,
        xpos: 4,
        ypos: 2,
        category: 'Chemistry',
        color: '#3FABA5',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 26,
        name: 'As',
        title: 'Astronomers',
        id: 26,
        xpos: 3,
        ypos: 5,
        category: 'Physics',
        color: '#F19F53',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 27,
        name: 'Etp',
        title: 'Environmental science teachers, postsecondary',
        id: 27,
        xpos: 4,
        ypos: 5,
        category: 'EnvironmentalScience',
        color: '#813F89',

    },
    {
        degree: 'Bachelor’s',
        num: 28,
        name: 'Mi',
        title: 'Microbiologists',
        id: 28,
        xpos: 5,
        ypos: 5,
        category: 'LifeSciences',
        color: '#2D3BBF',
    },
    {
        degree: 'Bachelor’s',
        num: 29,
        name: 'Ps',
        title: 'Physical sciences',
        id: 29,
        xpos: '',
        ypos: '',
        category: 'LifeSciences',
        color: '#2D3BBF',
    },
    {
        degree: 'Bachelor’s',
        num: 30,
        name: 'Ia',
        title: 'Information security analysts',
        id: 30,
        xpos: '',
        ypos: '',
        category: 'ComputerScience',
        color: '#FFC531',
        fontColor: '#171717',
    },
    {
        degree: 'Bachelor’s',
        num: 31,
        name: 'Gt',
        title: 'Geoscientists, except hydrologists and geographers',
        id: 31,
        xpos: '',
        ypos: '',
        category: 'Geoscience',
        color: '#648037',

    },

]



export const dataItemsInfo = [
    {
        id: 1,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Professor Raphael Mechoulam</li>
        </ul>,
    },
    {
        id: 2,
        blockDescriptionNominees: <ul>
            <li>Clarice Phelps</li>
            <li>Venkatraman ‘Venki’ Ramakrishnan</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 3,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 4,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 5,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 6,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 7,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 8,
        blockDescriptionNominees: <ul>
            <li>Lily Serna</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 9,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 10,
        blockDescriptionNominees: <ul>
            <li>Mary Anderson</li>
            <li>Béla Barényi</li>
            <li>EDOUARD BENEDICTUS</li>
            <li>Ali Hasan Nayfeh</li>
            <li>Nora Al Matrooshi</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Astrid Linder</li>
        </ul>,
    },
    {
        id: 11,
        blockDescriptionNominees: <ul>
            <li>Sherien Elagroudy</li>
            <li>Ayesha Abdulla Alkhoori</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },

    {
        id: 12,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 13,
        blockDescriptionNominees: <ul>
            <li>Dr Maia Saurena</li>
            <li>Graeme Milbourne Clark AC</li>
            <li>Dr Habiba AlSafar</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Patricia Bath  M.D.</li>
            <li>Tsuneko and Reiji Okazaki</li>
        </ul>,
    },
    {
        id: 14,
        blockDescriptionNominees: <ul>
            <li>Jim Frazier</li>
            <li>David Unaipon</li>
            <li>Karen Ann Hilsum Burt</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 15,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 16,
        blockDescriptionNominees: <ul>
            <li>Felicia Agubata</li>
            <li>Professor Albert Zomaya</li>
            <li>Mohamed M. Atalla</li>
            <li>Rachid Yazami</li>
            <li>Lina Jamil Karam</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 17,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 18,
        blockDescriptionNominees: <ul>
            <li>Margaret Elaine Hamilton</li>
            <li>Ada Lovelace</li>
            <li>Radia Joy Perlman</li>
            <li>Grace Hopper</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Fei-Fei Li</li>
        </ul>,
    },
    {
        id: 19,
        blockDescriptionNominees: <ul>
            <li>Kalpana Chawla</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 20,
        blockDescriptionNominees: <ul>
            <li>Samy Samandjeu</li>
            <li>Rebecca Enonchong</li>
            <li>Duhamel, Wu Ling Aurore</li>
            <li>Nicole Yap</li>
            <li>Mostafa Menessy</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 21,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 22,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 23,
        blockDescriptionNominees: <ul>
            <li>Marguerite Catherine Perey</li>
            <li>Lise Meitner</li>
            <li>Francisca Nneka Okeke</li>
            <li>Dr. Chien-Shiung Wu</li>
            <li>David Ho</li>
            <li>Cathy Foley</li>
            <li>Karen Ann Hilsum Burt</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Daphne Jackson</li>
            <li>Vicky Ghafi Kondi Akara</li>
        </ul>,
    },
    {
        id: 24,
        blockDescriptionNominees: <ul>
            <li>Words that Count</li>
            <li>Ramla Qureshi</li>
            <li>Marita Cheng</li>
            <li>Corey Aden Tutt</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li>Thomas W. Cole Jr.</li>
            <li>Stuart Naismith</li>
            <li>Sanya Mathura</li>
            <li>Amanda Obidike</li>
            <li>Professor Helen James OBE EurIng</li>
        </ul>,
    },
    {
        id: 25,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 26,
        blockDescriptionNominees: <ul>
            <li>Ellison Onizuka</li>
            <li>(LCDR, U.S. Navy) Jonny Kim</li>
            <li>Dr Tanya Hill</li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 27,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 28,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 29,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 30,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
    {
        id: 31,
        blockDescriptionNominees: <ul>
            <li></li>
        </ul>,
        blockDescriptionInductees: <ul>
            <li></li>
        </ul>,
    },
]

