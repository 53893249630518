import React from 'react';
import { Link } from 'react-router-dom';
import MyButton from '../ui/myButton/MyButton';
import cl from './ContactLine.module.css';

const ContactLine = ({children, idBtn, bodyBtn, ...props}) => {
    return (
        <div {...props} className={cl.contact_line_container}>
            <div className={cl.container}>
                <h1>{children}</h1>
                <div className={cl.btn_body}>
                    <Link to={"/contacts"}>
                        <MyButton id={idBtn}>
                            {bodyBtn}
                        </MyButton>
                    </Link>
                </div>
            </div>
            
            
            
        </div>
    );
};

export default ContactLine;