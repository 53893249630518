export const dataNavigationPanel = [
    {
        id: 'educ_page_title_podcast',
        title: 'PODCAST'
    },
    {
        id: 'educ_page_title_table',
        title: 'TABLE'
    },
    // {
    //     id: 'educ_page_title_gameApp',
    //     title: 'GAME APP'
    // },
    {
        id: 'educ_page_title_socMedia',
        title: 'SOCIAL MEDIA'
    }
]