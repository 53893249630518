import React, { useEffect, useState } from 'react';
import { MyCardsData } from './DataMyCards';
import cl from './MyCards.module.css';

const MyCards = () => {

    return (
        <div className={cl.cards_container}>
            {MyCardsData.map((item) => {
                return <div key={item.name} className={cl.cards}>
                    <div className={cl.cards_body}>
                        <div className={ cl.cards_icon }>
                            <img src={item.img} alt="person" />
                            {/* {item.icon} */}
                        </div>
                        <div id="about_us_cards_indo" className={cl.cards_info}>
                            <h2>{item.name}</h2>
                            <h3>{item.title}</h3> 
                            <p>{item.text}</p>
                        </div>
                    </div> 
                </div>
                    
            })}
            
        </div>
    );
};

export default MyCards;